import Vue from 'vue';

import ClickOutside from './ClickOutside';
import ResizeText from './ResizeText';
import SortableTable from './SortableTable';

export default {
  install(Vue) {
    Vue.directive('ClickOutside', ClickOutside);
    Vue.directive('ResizeText', ResizeText);
    Vue.directive('SortableTable', SortableTable);
  },
};

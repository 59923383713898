import { cloneDeep, isNil, isEqual, isEmpty, set, isArray } from 'lodash-es';
import Vue from 'vue';

import {
  companyService,
  authService,
  activityService,
  invoiceService,
  settingsService,
  bankReconciliationService,
  disputeManagementService,
  customerService,
  creditManagementService,
} from '@services';
import { MODES } from '@components/Shared/AppDrawer/utils/config';
import { i18n } from '@plugins';

import adapterFields from '@/views/Settings/BackOffice/utils/adapterFields';
import { demoApaUsers } from '@/views/Settings/ApAssistant/components/ApaUsers/components/demo-apa-users';

const { MODE, VITE_APP_DEBUG_MODE } = import.meta.env;
const isProduction = isEqual(MODE, 'production');
const isDebugMode = !isNil(VITE_APP_DEBUG_MODE) && isEqual(VITE_APP_DEBUG_MODE, 'true');

const needJsonAdapter = ['codat', 'qbo', 'xero'];

const generateShortHash = (length = 16, lowerCase = false) => {
  const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  let result = '';

  for (let i = 0; i < length; i += 1) {
    result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
  }

  return lowerCase ? result.toLowerCase() : result;
};

export default {
  fetchCompanySettings: async ({ commit }, payload) => {
    try {
      commit('LOADING_STATUS', true);

      const data = await companyService.fetchSettingsByCompanyId(payload);
      const { userCustomerRelations } = data;

      if (userCustomerRelations && !isEmpty(userCustomerRelations)) {
        Object.values(userCustomerRelations).forEach((value) => {
          if (isEmpty(value?.defaultUserId) || isNil(value?.defaultUserId)) {
            Vue.prototype.$showToast(
              'error',
              i18n.t('errorMessages.unassignedUser', {
                ucr: value?.translations?.[i18n.locale || 'en'],
              }),
              'unassignedUser',
            );

            Vue.prototype.$VueAnalytics.track({
              event_uuid: '97224391-460e-4722-99bb-f54ce686faf4',
              type: 'toast',
              action: 'shown',
              element: 'button',
              category: 'warning',
              label: 'assign_default_account_owner',
            });
          }
        });
      }

      commit('FETCH_COMPANY_SETTINGS', data);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('LOADING_STATUS', false);
    }
  },
  fetchAndUpdateCompanySetting: async ({ commit }, { companyId, name }) => {
    try {
      commit('LOADING_STATUS', true);

      const res = await companyService.fetchSpecificSettingsByCompanyId({ companyId, names: [name] });

      const value = res[name];

      commit('UPDATE_COMPANY_SETTING', { companyId, name, value });
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('LOADING_STATUS', false);
    }
  },
  updateCompanySetting: async ({ commit }, { companyId, name, value }) => {
    try {
      commit('LOADING_STATUS', true);
      await companyService.updateSettings({
        companyId,
        name,
        value,
      });

      commit('UPDATE_COMPANY_SETTING', { companyId, name, value });
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('LOADING_STATUS', false);
    }
  },
  insertCompanyUser: async ({ commit }, user) => {
    try {
      const { isNewUser, userId } = await companyService.createUser(user);

      if (isNewUser && !isNil(userId)) {
        user.id = userId;

        commit('ADD_COMPANY_USERS', user);

        return { isNewUser };
      }
    } catch ({ message }) {
      console.error(message);
    }
  },
  updateCompanyUser: async ({ commit, dispatch }, payload) => {
    try {
      const { showNotification = true, ...user } = payload;

      await companyService.updateCompanyUser(user);

      commit('UPDATE_COMPANY_USERS', user);

      if (showNotification) {
        dispatch(
          'notifications/presentNotification',
          {
            type: 'success',
            visible: true,
            message: i18n.t('notifications.message'),
          },
          { root: true },
        );
      }
    } catch ({ message }) {
      console.error(message);
    }
  },
  updateUser: async ({ dispatch }, user) => {
    try {
      await companyService.updateUser(user);

      dispatch(
        'notifications/presentNotification',
        {
          type: 'success',
          visible: true,
          message: i18n.t('notifications.message'),
        },
        { root: true },
      );
    } catch ({ message }) {
      console.error(message);
    }
  },
  updateCompanyUserSettings: async (_, { companyId, userId, settings }) => {
    try {
      await companyService.updateUserSettings({
        companyId,
        userId,
        settings,
      });
    } catch ({ message }) {
      console.error(message);
    }
  },
  getCompanyUserSettings: async (_, { companyId }) => {
    try {
      return await companyService.fetchUserHeaders({ companyId });
    } catch ({ message }) {
      console.error(message);
    }
  },
  loadCompanyUsers: async ({ commit }, { companyId }) => {
    try {
      let companyUsers = await companyService.fetchUsers({ companyId });

      // TODO - Will change at GS-2726
      companyUsers = companyUsers
        .filter((user) => user.email !== 'support@gaviti.com')
        .sort(({ name: name1 }, { name: name2 }) => name1.toLowerCase().localeCompare(name2.toLowerCase()));

      commit('LOAD_COMPANY_USERS', companyUsers);
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchReportData: async (_, { companyId }) => {
    try {
      return await companyService.fetchLoginReport({ companyId });
    } catch ({ message }) {
      console.error(message);
    }
  },
  loadCompanyApaUsers: async ({ commit }, { companyId }) => {
    try {
      const isDemoCompany = isEqual(Vue.auth.user()?.selectedCompany?.type, 'demo');
      let companyApaUsers = isDemoCompany ? demoApaUsers : await companyService.fetchApaUsers({ companyId });

      if (isNil(companyApaUsers) || (isArray(companyApaUsers) && isEmpty(companyApaUsers))) {
        commit('LOAD_COMPANY_APA_USERS', []);
        return;
      }

      if (isDemoCompany) {
        const numberOfCustomers = 25;
        const customers = await customerService.find({ companyId, limit: numberOfCustomers });

        companyApaUsers = companyApaUsers.map((user) => {
          const randomIndex = Math.floor(Math.random() * customers.length);
          const customer = customers[randomIndex];

          return {
            ...user,
            member: [
              {
                customerId: customer.id,
                companyId,
                customerName: customer.displayName,
                contactLevels: [0],
                customerCustomFields: [],
                invoiceIds: [],
              },
            ],
          };
        });
      }

      const filteredUsers = companyApaUsers
        .filter((user) => user.email !== 'support@gaviti.com')
        .sort(({ name: name1 }, { name: name2 }) => name1.toLowerCase().localeCompare(name2.toLowerCase()));

      commit('LOAD_COMPANY_APA_USERS', filteredUsers);
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchApaReportData: async (_, { companyId }) => {
    try {
      return await companyService.fetchApaLoginReport({ companyId });
    } catch ({ message }) {
      console.error(message);
    }
  },
  performPasswordReset: async () => {
    try {
      await authService.resetPassword();
    } catch ({ message }) {
      console.error(message);
    }
  },
  async showLogs({ dispatch, commit }) {
    await dispatch('app/toggleShowAppDrawer', { show: true, mode: MODES.WIDE, title: 'Logs' }, { root: true });
    commit('SHOW_LOGS');
  },
  hideLogs({ commit }) {
    commit('HIDE_LOGS');
  },
  demoActivities: async ({ dispatch }, _this) => {
    try {
      switch (_this.demoCompany.action) {
        case 'create': {
          await activityService.createDemo({
            companyId: _this.$auth.user()?.selectedCompany?.id,
          });

          break;
        }

        case 'clean': {
          await activityService.cleanDemo({
            companyId: _this.$auth.user()?.selectedCompany?.id,
          });

          break;
        }

        case 'generate': {
          await invoiceService.createDemoClosed({
            companyId: _this.$auth.user()?.selectedCompany?.id,
          });

          break;
        }

        case 'generatePaid': {
          await invoiceService.createDemoPaid({
            companyId: _this.$auth.user()?.selectedCompany?.id,
          });

          break;
        }

        case 'generateBankTransactions': {
          await bankReconciliationService.generateDemo({
            companyId: _this.$auth.user()?.selectedCompany?.id,
          });

          break;
        }

        case 'generateDisputes': {
          await disputeManagementService.generateDemo({
            companyId: _this.$auth.user()?.selectedCompany?.id,
          });

          break;
        }

        case 'generateCreditManagement': {
          await creditManagementService.generateDemo({
            companyId: _this.$auth.user()?.selectedCompany?.id,
          });

          break;
        }

        default:
          throw new TypeError(`The action '${_this.demoCompany.action}' is not supported!`);
      }

      dispatch(
        'notifications/presentNotification',
        {
          type: 'success',
          visible: true,
          message: i18n.t('notifications.message'),
        },
        { root: true },
      );
    } catch ({ message }) {
      dispatch(
        'notifications/presentNotification',
        {
          type: 'error',
          visible: true,
          message,
        },
        { root: true },
      );
    }
  },
  getAdaptersList: async (_, companyId) => {
    try {
      return await companyService.fetchAdaptersByCompanyId({ companyId });
    } catch ({ message }) {
      console.error(message);
    }
  },
  updateAdapter: async (_, { companyId, name, fields, upsert = false }) => {
    try {
      return await companyService.updateAdapter({
        companyId,
        name,
        fields,
        upsert,
      });
    } catch ({ message }) {
      console.error(message);
    }
  },
  runAdapterIndex: async (_, { name, token, body, contentType }) => {
    try {
      return await companyService.runAdapterIndex({
        name,
        token,
        body,
        contentType,
      });
    } catch ({ message }) {
      console.error(message);

      throw new Error(message);
    }
  },
  runAdapterMethod: async (_, payload) => {
    try {
      return await companyService.runAdapterMethod(payload);
    } catch ({ message }) {
      console.error(message);

      throw new Error(message);
    }
  },
  createMerchant: async (_, payload) => {
    try {
      return await companyService.createMerchant(payload);
    } catch ({ message }) {
      console.error(message);

      throw new Error(message);
    }
  },
  findMerchant: async ({ commit }, payload) => {
    try {
      const merchant = await companyService.findMerchant(payload);

      commit('SET_MERCHANTS', merchant);
    } catch ({ message }) {
      console.error(message);

      throw new Error(message);
    }
  },
  checkWorkflowConfigurations: async ({ commit }) => {
    try {
      const {
        selectedCompany: { id: companyId },
      } = Vue.auth.user();

      const configurations = await companyService.checkWorkflowConfigurations({ companyId });

      commit('SET_WORKFLOW_CONFIGURATIONS_CHECK', configurations);
    } catch ({ message }) {
      console.error(message);
    }
  },
  createAdapter: async ({ dispatch }, { type, companyId }) => {
    const adapterConfig = cloneDeep(adapterFields(companyId)[type]);

    set(adapterConfig, 'name.value', `${adapterConfig?.code.value}-${generateShortHash()}`);
    set(adapterConfig, 'active.value', true);

    if (needJsonAdapter.includes(type)) {
      const jsonAdapterConfig = cloneDeep(adapterFields(companyId).json);

      set(jsonAdapterConfig, 'name.value', `${jsonAdapterConfig?.gavitiType.value}-${type}-${generateShortHash()}`);
      set(jsonAdapterConfig, 'active.value', true);

      if (isEqual(type, 'qbo')) {
        set(jsonAdapterConfig, 'customFieldsMapping.value', {
          customer: {
            mappings: [
              {
                key: { value: 'id' },
                value: { value: 'Id' },
              },
              {
                key: { value: 'displayId' },
                value: { value: 'Id' },
              },
              {
                key: { value: 'displayName' },
                value: { value: 'FullyQualifiedName' },
              },
              {
                key: { value: 'emailsLevel_1' },
                value: { value: 'PrimaryEmailAddr.Address' },
              },
              {
                key: { value: 'phone' },
                value: { value: 'PrimaryPhone.FreeFormNumber' },
              },
            ],
          },
          invoice: {
            mappings: [
              {
                key: { value: 'id' },
                value: { value: 'Id' },
              },
              {
                key: { value: 'displayId' },
                value: { value: 'DocNumber' },
              },
              {
                key: { value: 'customerId' },
                value: { value: 'CustomerRef.value' },
              },
              {
                key: { value: 'dueDate' },
                value: { value: 'DueDate' },
              },
              {
                key: { value: 'balance' },
                value: { value: 'Balance' },
              },
              {
                key: { value: 'localBalance' },
                value: { value: 'Balance' },
              },
              {
                key: { value: 'totalAmount' },
                value: { value: 'TotalAmt' },
              },
              {
                key: { value: 'localTotalAmount' },
                value: { value: 'TotalAmt' },
              },
              {
                key: { value: 'createDate' },
                value: { value: 'MetaData.CreateTime' },
              },
              {
                key: { value: 'currency' },
                value: { value: 'CurrencyRef.value' },
              },
              {
                key: { value: 'link' },
                value: { value: 'InvoiceLink' },
              },
            ],
          },
        });
        set(jsonAdapterConfig, 'calculatedFields.value', {
          customer: {
            contactName:
              // eslint-disable-next-line no-template-curly-in-string
              "return `${itemRaw['GivenName'] ? itemRaw['GivenName'] : ''} ${itemRaw['FamilyName'] ? itemRaw['FamilyName'] : ''}`.trim();",
          },
        });
      }

      if (isEqual(type, 'xero')) {
        set(jsonAdapterConfig, 'customFieldsMapping.value', {
          customer: {
            mappings: [
              {
                key: { value: 'id' },
                value: { value: 'ContactID' },
              },
              {
                key: { value: 'displayId' },
                value: { value: 'ContactNumber' },
              },
              {
                key: { value: 'displayName' },
                value: { value: 'Name' },
              },
              {
                key: { value: 'contactName' },
                value: { value: 'FirstName' },
              },
              {
                key: { value: 'emailsLevel_1' },
                value: { value: 'EmailAddress' },
              },
            ],
          },
          invoice: {
            mappings: [
              {
                key: { value: 'id' },
                value: { value: 'InvoiceID' },
              },
              {
                key: { value: 'customerId' },
                value: { value: 'Contact.ContactID' },
              },
              {
                key: { value: 'displayId' },
                value: { value: 'InvoiceNumber' },
              },
              {
                key: { value: 'totalAmount' },
                value: { value: 'Total' },
              },
              {
                key: { value: 'dueDate' },
                value: { value: 'DueDate' },
              },
              {
                key: { value: 'createDate' },
                value: { value: 'Date' },
              },
              {
                key: { value: 'currency' },
                value: { value: 'CurrencyCode' },
              },
              {
                key: { value: 'balance' },
                value: { value: 'AmountDue' },
              },
            ],
          },
        });
      }

      if (isEqual(type, 'codat')) {
        set(jsonAdapterConfig, 'customFieldsMapping.value', {
          customer: {
            mappings: [
              {
                key: { value: 'id' },
                value: { value: 'id' },
              },
              {
                key: { value: 'displayId' },
                value: { value: 'id' },
              },
              {
                key: { value: 'displayName' },
                value: { value: 'customerName' },
              },
              {
                key: { value: 'emailsLevel_1' },
                value: { value: 'emailAddress' },
              },
              {
                key: { value: 'phone' },
                value: { value: 'phone' },
              },
            ],
          },
          invoice: {
            mappings: [
              {
                key: { value: 'id' },
                value: { value: 'id' },
              },
              {
                key: { value: 'displayId' },
                value: { value: 'invoiceNumber' },
              },
              {
                key: { value: 'customerId' },
                value: { value: 'customerRef.id' },
              },
              {
                key: { value: 'createDate' },
                value: { value: 'issueDate' },
              },
              {
                key: { value: 'dueDate' },
                value: { value: 'dueDate' },
              },
              {
                key: { value: 'totalAmount' },
                value: { value: 'totalAmount' },
              },
              {
                key: { value: 'balance' },
                value: { value: 'amountDue' },
              },
              {
                key: { value: 'currency' },
                value: { value: 'currency' },
              },
            ],
          },
        });
      }

      const jsonAdapter = await dispatch(
        'backOffice/createAdapterInstance',
        {
          adapterConfig: jsonAdapterConfig,
          editMode: false,
        },
        { root: true },
      );

      await dispatch('updateAdapter', {
        companyId,
        name: jsonAdapter.name,
        fields: jsonAdapter,
        upsert: true,
      });

      set(adapterConfig, 'gavitiJsonAdapterName.value', jsonAdapter.name);
    }

    if (isEqual(type, 'qbo')) {
      set(adapterConfig, 'fetchCredits.value', true);
    }

    if (isEqual(type, 'xero')) {
      set(adapterConfig, 'fetchCredits.value', true);
    }

    if (isEqual(type, 'codat') && (isDebugMode || !isProduction)) {
      set(adapterConfig, 'baseURL.value', 'https://api-uat.codat.io/');
    }

    const adapter = await dispatch(
      'backOffice/createAdapterInstance',
      {
        adapterConfig,
        editMode: false,
      },
      { root: true },
    );

    await dispatch('updateAdapter', {
      companyId,
      name: adapter.name,
      fields: adapter,
      upsert: true,
    });

    return adapter;
  },
  templateFile: async (_, payload) => {
    try {
      return await companyService.getTemplateFile(payload);
    } catch ({ message }) {
      console.error(message);
    }
  },
  uploadCompanyLogo: async ({ dispatch }, payload) => {
    try {
      await companyService.uploadLogo(payload);

      dispatch(
        'notifications/presentNotification',
        {
          type: 'success',
          visible: true,
          message: i18n.t('notifications.message'),
        },
        { root: true },
      );
    } catch ({ message }) {
      console.error(message);

      throw new Error(message);
    }
  },
  presentModal: ({ commit }, payload) => {
    commit('SET_MODAL', {
      ...payload,
      open: true,
    });
  },
  dismissModal: ({ commit }) => {
    commit('SET_MODAL', {
      open: false,
      title: undefined,
      contentType: undefined,
    });
  },
  importCustomFields: async ({ dispatch }, file) => {
    try {
      const {
        selectedCompany: {
          id: companyId,
          name: { en: companyName },
        },
      } = Vue.auth.user();

      const formData = new FormData();

      formData.append('companyId', companyId);
      formData.append('companyName', companyName);
      formData.append('file', file);

      await settingsService.importCustomFields(formData);

      await dispatch('fetchCompanySettings', { companyId });

      dispatch(
        'notifications/presentNotification',
        {
          type: 'success',
          visible: true,
          message: i18n.t('notifications.message'),
        },
        { root: true },
      );
    } catch ({ message }) {
      console.error(message);
    }
  },
  updateCustomField: async ({ dispatch }, payload) => {
    try {
      const {
        selectedCompany: { id: companyId },
      } = Vue.auth.user();

      await settingsService.updateCustomField({
        companyId,
        ...payload,
      });

      await dispatch('fetchCompanySettings', { companyId });
    } catch ({ message }) {
      console.error(message);
    }
  },
  importInternalPersonas: async ({ dispatch }, file) => {
    try {
      const {
        selectedCompany: {
          id: companyId,
          name: { en: companyName },
        },
      } = Vue.auth.user();

      const formData = new FormData();

      formData.append('companyId', companyId);
      formData.append('companyName', companyName);
      formData.append('file', file);

      await settingsService.importInternalPersonas(formData);

      await dispatch('fetchCompanySettings', { companyId });

      dispatch(
        'notifications/presentNotification',
        {
          type: 'success',
          visible: true,
          message: i18n.t('notifications.message'),
        },
        { root: true },
      );
    } catch ({ message }) {
      console.error(message);
    }
  },
  updateInternalPersonas: async ({ dispatch }, payload) => {
    try {
      const {
        selectedCompany: { id: companyId },
      } = Vue.auth.user();

      await settingsService.updateInternalPersonas({
        companyId,
        ...payload,
      });

      await dispatch('fetchCompanySettings', { companyId });
    } catch ({ message }) {
      console.error(message);
    }
  },
  getPlaidLinkToken: async ({}) => {
    try {
      return companyService.plaidLinkToken({ companyId: Vue.auth.user()?.selectedCompany?.id });
    } catch ({ message }) {
      console.error(message);

      throw new Error(message);
    }
  },
  getStripeLinkToken: async ({}) => {
    try {
      return companyService.stripeLinkToken({ companyId: Vue.auth.user()?.selectedCompany?.id });
    } catch ({ message }) {
      console.error(message);

      throw new Error(message);
    }
  },
  plaidConnect: async ({}, payload) => {
    try {
      return companyService.plaidConnect({ companyId: Vue.auth.user()?.selectedCompany?.id, ...payload });
    } catch ({ message }) {
      console.error(message);

      throw new Error(message);
    }
  },
  getPlaidReconnectLinkToken: async ({}, payload) => {
    try {
      return companyService.plaidReconnectLinkToken({ companyId: Vue.auth.user()?.selectedCompany?.id, ...payload });
    } catch ({ message }) {
      console.error(message);

      throw new Error(message);
    }
  },
  plaidReconnect: async ({}, payload) => {
    try {
      return companyService.plaidReconnect({ companyId: Vue.auth.user()?.selectedCompany?.id, ...payload });
    } catch ({ message }) {
      console.error(message);

      throw new Error(message);
    }
  },
  plaidDeleteAccount: async ({}, payload) => {
    try {
      return companyService.plaidDeleteAccount({ companyId: Vue.auth.user()?.selectedCompany?.id, ...payload });
    } catch ({ message }) {
      console.error(message);

      throw new Error(message);
    }
  },
  getApaOnBoardingTemplate: async ({}, payload) => {
    try {
      return await companyService.getApaOnBoardingTemplate(payload);
    } catch ({ message }) {
      console.error(message);
    }
  },
  getAllocationSuggestionEmailTemplate: async ({}, payload) => {
    try {
      return await companyService.getAllocationSuggestionEmailTemplate(payload);
    } catch ({ message }) {
      console.error(message);
    }
  },
  getCompanyAuditList: async ({ commit }, payload) => {
    try {
      const {
        selectedCompany: { id: companyId },
      } = Vue.auth.user();

      const { list = [], stats = {} } = await companyService.fetchCompanyAuditList({
        ...payload,
        companyId,
      });

      commit('SET_COMPANY_AUDIT_LIST', list);
      commit('SET_COMPANY_AUDIT_STATS', stats);
    } catch ({ message }) {
      console.error(message);
    }
  },
  createPublicAPIKey: async ({}) => {
    try {
      const {
        selectedCompany: { id: companyId },
      } = Vue.auth.user();

      return await settingsService.createPublicAPIKey({
        companyId,
      });
    } catch (error) {
      console.error(error);
    }
  },
  deletePublicAPIKey: async ({}, payload) => {
    try {
      const {
        selectedCompany: { id: companyId },
      } = Vue.auth.user();

      return await settingsService.deletePublicAPIKey({
        ...payload,
        companyId,
      });
    } catch (error) {
      throw new Error(error);
    }
  },
  readPublicAPIKey: async ({}) => {
    try {
      const {
        selectedCompany: { id: companyId },
      } = Vue.auth.user();

      return await settingsService.readPublicAPIKey({
        companyId,
      });
    } catch (error) {
      console.error(error);
    }
  },
  exportCompanyAuditList: async ({ commit }, payload) => {
    try {
      commit('SET_IS_AUDIT_EXPORT_LOADING', true);

      const labels = {
        entity: {
          workflow: i18n.t('settings.auditTable.labels.workflow'),
          'activity-log': i18n.t('settings.auditTable.labels.activityLog'),
        },
        action: {
          updateWorkflow: i18n.t('settings.auditTable.labels.updateWorkflow'),
          updateWorkflowRule: i18n.t('settings.auditTable.labels.updateWorkflowRule'),
          editNote: i18n.t('settings.auditTable.labels.editNote'),
        },
      };

      const { headers, list } = payload;

      let csv = '';

      if (!isEmpty(list)) {
        csv += `${headers.map(({ text }) => text).join(',')}\n`;

        list.forEach((item) => {
          const row = headers.map(({ value }) => {
            if (value === 'details') {
              return JSON.stringify(item[value]);
            }

            if (value === 'entity') {
              return labels.entity[item[value]];
            }

            if (value === 'action') {
              return labels.action[item[value]];
            }

            if (value === 'user') {
              return `${item[value]?.name} (${item[value]?.email})`;
            }

            return item[value];
          });

          csv += `${row.join(',')}\n`;
        });

        const blob = new Blob([csv], { type: 'text/csv' });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', `audit_${payload.currentDate}.csv`);

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);

        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error(error);
    } finally {
      commit('SET_IS_AUDIT_EXPORT_LOADING', false);
    }
  },
};

import Sortable from 'sortablejs';

const sortableInstances = new WeakMap();

const SortableTable = {
  inserted: (el, binding) => {
    const firstRow = el.querySelector('tr');

    if (!firstRow) return;

    firstRow.firstElementChild.classList.add('locked');

    const sortable = Sortable.create(firstRow, {
      ...(binding.value || {}),
      handle: '.handle',
      filter: 'locked',
      animation: 150,
      swap: true,
      onMove: (e) => !e.related.classList.contains('locked'),
    });

    sortableInstances.set(el, sortable);
  },
  unbind: (el) => {
    const instance = sortableInstances.get(el);

    if (instance) {
      instance.destroy();
      sortableInstances.delete(el);
    }

    const firstRow = el.querySelector('tr');

    if (firstRow?.firstElementChild) {
      firstRow.firstElementChild.classList.remove('locked');
    }
  },
};

export default SortableTable;

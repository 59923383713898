const ADAPTERS = {
  TYPES: {
    ATTACHMENT: {
      text: 'Attachment',
      value: 'attachment',
    },
    SFTP_ATTACHMENT: {
      text: 'SFTP - Attachment',
      value: 'sftpAttachment',
    },
    SFTP_DATA: {
      text: 'SFTP - DATA',
      value: 'sftpData',
    },
    FTP_ATTACHMENT: {
      text: 'FTP - Attachment',
      value: 'ftpAttachment',
    },
    FTP_DATA: {
      text: 'FTP - DATA',
      value: 'ftpData',
    },
    FTPS_ATTACHMENT: {
      text: 'FTPS - Attachment',
      value: 'ftpsAttachment',
    },
    EVO: {
      text: 'EVO',
      value: 'evo',
    },
    BLUESNAP: {
      text: 'Bluesnap',
      value: 'bluesnap',
    },
    CWA: {
      text: 'Cwa',
      value: 'cwa',
    },
    STRIPE: {
      text: 'Stripe',
      value: 'stripe',
    },
    PRIORITY2: {
      text: 'Priority',
      value: 'priority2',
    },
    QBO: {
      text: 'QBO',
      value: 'qbo',
    },
    XERO: {
      text: 'Xero',
      value: 'xero',
    },
    QBWC: {
      text: 'QBWC',
      value: 'qbwc2',
    },
    SALESFORCE: {
      text: 'Salesforce',
      value: 'salesforce',
    },
    CODAT: {
      text: 'Codat',
      value: 'codat',
    },
    NETSUITE: {
      text: 'NetSuite',
      value: 'net-suite',
    },
    PLAID: {
      text: 'Plaid',
      value: 'plaid',
    },
    XLSX: {
      text: 'XLSX',
      value: 'xlsx',
    },
    CSV: {
      text: 'CSV',
      value: 'csv',
    },
    JSON: {
      text: 'JSON',
      value: 'json',
    },
    JSON_CODAT: {
      text: 'JSON for Codat',
      value: 'jsonCodat',
    },
    JSON_PRIORITY: {
      text: 'JSON for Priority',
      value: 'jsonPriority',
    },
    JSON_QBO: {
      text: 'JSON for Qbo',
      value: 'jsonQbo',
    },
    JSON_QBWC: {
      text: 'JSON for Qbwc',
      value: 'jsonQbwc',
    },
    JSON_NETSUITE: {
      text: 'JSON for NetSuite',
      value: 'jsonNetSuite',
    },
    XML: {
      text: 'XML',
      value: 'xml',
    },
    TXT: {
      text: 'Text',
      value: 'txt',
    },
    UNIVERSAL: {
      text: 'Cash Application',
      value: 'universal',
    },
    SAGE_INTACCT: {
      text: 'Sage Intacct',
      value: 'sage-intacct',
    },
    JSON_SAGE_INTACCT: {
      text: 'JSON for Sage Intacct',
      value: 'jsonSageIntacct',
    },
    SFTP_CUSTOM_FIELDS_FILES: {
      text: 'SFTP - Custom Fields Files',
      value: 'sftpCustomFieldsFiles',
    },
  },
  INVOICE_ENTITY_TYPES: {
    TFNCITEMS2ONE: {
      text: 'TFNCITEMS2ONE',
      value: 'TFNCITEMS2ONE',
    },
    TFNCITEMS3ONE: {
      text: 'TFNCITEMS3ONE',
      value: 'TFNCITEMS3ONE',
    },
  },
  TRANSFORMATIONS: {
    TYPES: {
      REPLACE: {
        text: 'Replace',
        value: 'replace',
      },
      REPLACE_TO_EMPTY: {
        text: 'Replace to empty',
        value: 'replaceToEmpty',
      },
      PARSE_DATE: {
        text: 'Parse date',
        value: 'parseDate',
      },
      TRIM: {
        text: 'Trim',
        value: 'trim',
      },
      TO_STRING: {
        text: 'To string',
        value: 'toString',
      },
    },
  },
  FILTER_ROW_RULES: {
    TYPES: {
      IS: {
        text: 'Is',
        value: 'is',
      },
      IS_IN: {
        text: 'Is in',
        value: 'isIn',
      },
      IS_EMPTY: {
        text: 'Is empty',
        value: 'isEmpty',
      },
      IS_NOT: {
        text: 'Is not',
        value: 'isNot',
      },
      IS_NOT_IN: {
        text: 'Is not in',
        value: 'isNotIn',
      },
      IS_NOT_EMPTY: {
        text: 'Is not empty',
        value: 'isNotEmpty',
      },
      GREATER: {
        text: 'Greater',
        value: 'greater',
      },
      GREATER_THAN_OR_EQUAL: {
        text: 'Greater than or equal',
        value: 'greaterThanOrEqual',
      },
      LOWER: {
        text: 'Lower',
        value: 'lower',
      },
      LOWER_THAN_OR_EQUAL: {
        text: 'Lower than or equal',
        value: 'lowerThanOrEqual',
      },
      STARTS_WITH: {
        text: 'Starts with',
        value: 'startsWith',
      },
      STARTS_WITH_ONE_FROM_LIST: {
        text: 'Starts with one from list',
        value: 'startsWithOneFromList',
      },
      STARTS_WITH_NOT: {
        text: 'Starts with not',
        value: 'startsWithNot',
      },
      ENDS_WITH: {
        text: 'Ends with',
        value: 'endsWith',
      },
      ENDS_WITH_NOT: {
        text: 'Ends with not',
        value: 'endsWithNot',
      },
      REGEX: {
        text: 'Regex',
        value: 'regex',
      },
    },
  },
  ENCRYPTED_KEYS: [
    'password',
    'clientId',
    'clientSecret',
    'accessToken',
    'refreshToken',
    'realmId',
    'consumerKey',
    'certificationPrivateKey',
    'privateKey',
    'apiCredentialsPassword',
    'devicePassword',
    'privateSecurityKey',
    'secretKey',
    'sageUserId',
    'sageUserPassword',
  ],
  ADAPTER_FIELDS_NOT_TO_EXPORT: [
    'gavitiJsonAdapterName',
    'gavitiAdapterName',
    'attachmentAdapterName',
    'password',
    'clientId',
    'clientSecret',
    'accessToken',
    'refreshToken',
    'realmId',
    'consumerKey',
    'certificationPrivateKey',
    'privateKey',
    'apiCredentialsPassword',
    'devicePassword',
    'privateSecurityKey',
    'secretKey',
    'sageUserId',
    'sageUserPassword',
  ],
};

Object.freeze(ADAPTERS);

export default ADAPTERS;
